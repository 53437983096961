.vertical-middle {
  vertical-align: middle;
}

.m-1 {
  margin: 8px;
}

.m-0\.5 {
  margin: 4px;
}

.ml-0\.5 {
  margin-left: 4px;
}

.mr-0\.5 {
  margin-right: 4px;
}

.mr-1 {
  margin-right: 8px;
}

.w-full {
  width: 100%;
}

.w-2 {
  width: 16px;
}

.w-4 {
  width: 32px;
}

.w-12 {
  width: 96px;
}

.h-full {
  height: 100%;
}

.h-2 {
  height: 16px;
}

.h-4 {
  height: 32px;
}

.h-12 {
  height: 96px;
}

.round-1 {
  border-radius: 8px;
}

.round-2 {
  border-radius: 16px;
}

.opacity-40 {
  opacity: 0.4;
}

.base-color {
  color: #212B36
}

.object-fit-contain {
  object-fit: contain;
}

.object-fit-cover {
  object-fit: cover;
}

.object-position-top {
  object-position: top;
}

.flyingBtn {
  z-index: 1001;
  transform-origin: center bottom;
  transform: scale(1);
}
